'use client'

import { siteConfig } from '@/config/site.config'
import getAPI from '@/lib/utils/api-client'
import { format } from 'date-fns'
import Image from 'next/image'
import Link from 'next/link'
import { useEffect, useRef, useState } from 'react'
import { MdOutlineChat } from 'react-icons/md'
import { VscHubot } from 'react-icons/vsc'
import { PulseLoader } from 'react-spinners'
import { getLangCode, I18nDictionary, useI18n } from '../i18n/lang-context'
import { Flex, FlexCol } from '../ui/flex'
import { useScreen } from '../utils/windowSize'

const I18N: I18nDictionary = {
  placeholder: { en: 'Ask your question', fr: 'Posez votre question' },
  send: { en: 'Send', fr: 'Envoyer' },
  welcomeMessage: {
    en: 'Hello, I am the research assistant. What can I do for you?',
    fr: 'Bonjour, je suis l\'assistant de recherche. Que puis-je faire pour vous ?'
  },
  waitingForResponse: {
    en: 'Waiting for response',
    fr: 'En train de répondre'
  }
}

type Message = {
  role: 'user' | 'assistant',
  content: string,
  date: Date
}

function AssistantDialog({open}: {open: boolean}) {

  const api = getAPI()
  const i18n = useI18n(I18N)
  const [msg, setMsg] = useState<string>('')
  const [messages, setMessages] = useState<Message[]>([])
  const [waiting, setWaiting] = useState(false)

  const sendMsg = async () => {
    if (waiting) return
    console.log('GPT API - Send message:', msg)
    console.log('GPT API - Waiting for response...')
    setWaiting(true)
    const _messages: Message[] = [...messages, { role: 'user', content: msg, date: new Date() }]
    setMessages(_messages)
    setMsg('')
    try {
      const response = await api.post('/gpt/chat', { messages: _messages, langCode: getLangCode() })
      console.log('GPT API - Response:', response)
      setMessages(_messages => [..._messages, { role: 'assistant', content: response, date: new Date() }])
    } catch (error) {
      console.log('GPT API - Error:', error)
      setMessages(_messages => [..._messages, { role: 'assistant', content: 'Error: ' + error, date: new Date() }])
    }
    setWaiting(false)
  }

  const textRef = useRef<HTMLTextAreaElement>(null)
  useEffect(() => {
    if (open) textRef.current?.focus()
  }, [open])
  const bottomRef = useRef<HTMLDivElement>(null)
  const scrollToBottom = () => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' })
  }
  useEffect(() => {
    if (!messages.length) reset()
    scrollToBottom()
  }, [messages])

  const reset = () => {
    setMessages([{
      role: 'assistant',
      content: i18n.welcomeMessage,
      date: new Date()
    }])
  }

  return (
    <FlexCol className='max-h-[90vh] w-[calc(100vw-2rem)] gap-4 rounded-2xl bg-primary p-4 shadow-lg sm:w-[500px]'>
      <Flex className='w-full cursor-default select-none items-center justify-center gap-4 text-white' onClick={() => reset()}><VscHubot size={24} />Jarvix</Flex>
      <div className='relative h-fit w-full overflow-hidden rounded-lg'>
        <FlexCol className='max-h-[500px] min-h-[400px] w-full flex-nowrap gap-2 overflow-y-scroll rounded-md bg-background p-4 text-sm font-light sm:min-h-[500px]'
          onScroll={e => e.stopPropagation()}>
          {messages.map((m, i) => (
            <FlexCol className='w-full' key={i}
              style={{
                paddingLeft: m.role === 'user' ? '12px' : 0,
                paddingRight: m.role === 'user' ? 0 : '12px',
                alignItems: m.role === 'user' ? 'flex-end' : 'flex-start'
              }}>
              <div className='border-1-primary border-1 rounded-md p-1 px-2 shadow-sm'
                style={{
                  textAlign: m.role === 'user' ? 'right' : 'left',
                  backgroundColor: m.role === 'user' ? 'rgba(0, 0, 0, 0.05)' : 'rgba(80, 72, 229, 0.1)'
                }}>{m.content}</div>
              <div className='p-[2px] px-2 text-[10px] font-light text-gray-500'>{format(m.date, 'HH:mm')}</div>
            </FlexCol>
          ))}
          {waiting && <Flex className='w-full items-end justify-center gap-[2px] font-normal italic opacity-75'>{i18n.waitingForResponse}<PulseLoader color='white' size={2} className='pb-[3px] opacity-75 brightness-0 dark:brightness-100' /></Flex>}
          <div style={{ float: 'left', clear: 'both' }} ref={bottomRef} />
          <div className='pointer-events-none absolute left-0 top-0 h-full w-full bg-transparent shadow-insetDarkStrong' />
        </FlexCol>
      </div>
      <textarea
        ref={textRef}
        className='max-h-[112px] min-h-[52px] w-full rounded-lg bg-background p-4 text-sm shadow-insetDarkStrong'
        value={msg}
        onChange={e => setMsg(e.target.value)}
        placeholder={i18n.placeholder}
        rows={1}
        onKeyDown={e => {
          if (e.key === 'Enter' && !e.shiftKey && !waiting) {
            e.preventDefault()
            sendMsg()
          }
        }}
      />
      <Link href={siteConfig.links.jarvix} target='_blank' rel='noreferrer' className='w-full select-none'>
        <Flex className='w-full items-center justify-center gap-2 text-xs text-slate-300 opacity-70 grayscale transition-all hover:underline hover:opacity-100 hover:grayscale-0'>
          <Image src={siteConfig.logo.jarvix} alt={'jarvix.com'} width={16} height={16} />
          Powered by Jarvix
        </Flex>
      </Link>
    </FlexCol>
  )
}

export function Assistant() {

  const [open, setOpen] = useState(false)
  const screen = useScreen()

  useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : 'unset'
  }, [open])

  return (
    <>
      <div id='ASSISTANT_DIALOG_BACKDROP' className='fixed bottom-0 left-0 z-[50] h-full w-full bg-black/20 backdrop-blur-sm transition-all' onClick={() => setOpen(false)} style={{
        opacity: open ? 1 : 0,
        pointerEvents: open ? 'all' : 'none'
      }} />
      <div className='fixed bottom-4 right-4 z-50'>
        <div id='ASSISTANT_BUTTON' className='scale-[98%] cursor-pointer select-none rounded-full bg-primary p-2 text-white shadow-sm transition-all hover:scale-100 hover:shadow-lg sm:p-4' onClick={() => setOpen(o => !o)}>
          <MdOutlineChat size={screen.sm ? 32 : 24} />
        </div>
        <div id='ASSISTANT_DIALOG_CONTAINER' className='absolute bottom-[110%] right-0 transition-all sm:bottom-[100%] sm:right-[100%]' style={{
          transform: 'scale(' + (open ? 1 : 0) + ')',
          transformOrigin: 'bottom right',
          opacity: open ? 1 : 0
        }}>
          <AssistantDialog open={open} />
        </div>
      </div>
    </>
  )
}
