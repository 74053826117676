'use client'

import { siteConfig } from '@/config/site.config'
import { useTheme } from 'next-themes'
import { useEffect, useRef } from 'react'
import { getLangCode, getLanguage } from '../i18n/lang-context'

export function LinkedInFollow() {

  const followRef = useRef<HTMLDivElement>(null) // Reference to the div where the button should be placed
  useEffect(() => {
    // Clear previous content
    if (followRef.current) {
      followRef.current.innerHTML = ''
    }
    // Ensure LinkedIn script is loaded
    let script = document.querySelector('script[src=\'https://platform.linkedin.com/in.js\']') as HTMLScriptElement
    script = document.createElement('script')
    script.src = 'https://platform.linkedin.com/in.js'
    script.type = 'text/javascript'
    script.text = 'lang: ' + getLanguage()
    document.body.appendChild(script)

    // Re-initialize LinkedIn plugins after script loads
    script.onload = () => {
      if (window.IN) {
        window.IN.init()
        if (followRef.current) {
          // Clear any existing content
          followRef.current.innerHTML = ''

          // Create a new LinkedIn Follow Button inside our div
          const followScript = document.createElement('script')
          followScript.type = 'IN/FollowCompany'
          followScript.setAttribute('data-id', siteConfig.links.linkedinId)
          followScript.setAttribute('data-counter', 'right')
          followScript.text = 'lang: ' + getLanguage()

          followRef.current.appendChild(followScript)
          window.IN.parse() // Force LinkedIn to render the button in place
        }
      }
    }

    return () => {
      document.body.removeChild(script) // Clean up old scripts when component unmounts or updates
    }
  }, [])

  return (!siteConfig.links.linkedinId ? <></> :
    <div ref={followRef} />
  )
}

export function TwitterFollow() {
  const followRef = useRef<HTMLDivElement>(null)
  const { theme } = useTheme()

  useEffect(() => {
    // Clear previous content
    if (followRef.current) {
      followRef.current.innerHTML = ''
    }

    // Create Twitter Follow Button
    const anchor = document.createElement('a')
    anchor.setAttribute('href', 'https://twitter.com/' + siteConfig.links.twitterHandle)
    anchor.setAttribute('class', 'twitter-follow-button')
    anchor.setAttribute('data-show-count', 'true') // Ensure follower count is shown
    anchor.setAttribute('data-show-screen-name', 'false') // Ensure account is not shown
    anchor.setAttribute('data-lang', getLangCode()) // Language setting
    anchor.setAttribute('data-theme', theme || 'dark') // Dark/Light theme

    followRef.current?.appendChild(anchor)

    // // Ensure Twitter script is removed before adding a new one
    // const existingScript = document.querySelector('script[src='https://platform.twitter.com/widgets.js']')
    // if (existingScript) {
    //   existingScript.remove()
    // }

    // Reload Twitter script
    const script = document.createElement('script')
    script.src = 'https://platform.twitter.com/widgets.js'
    script.async = true
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script) // Clean up old scripts when component unmounts or updates
    }
  }, [])

  return (!siteConfig.links.twitterHandle ? <></> :
    <div ref={followRef} />
  )
}

export function YouTubeSubscribe() {
  const subscribeRef = useRef<HTMLDivElement>(null)
  const { theme } = useTheme()

  useEffect(() => {
    // Clear previous content
    if (subscribeRef.current) {
      subscribeRef.current.innerHTML = ''
    }

    // Create YouTube Subscribe Button
    const div = document.createElement('div')
    div.className = 'g-ytsubscribe'
    div.setAttribute('data-channelid', siteConfig.links.youtubeChannelId)
    div.setAttribute('data-layout', 'default') // 'full' for a wider button
    div.setAttribute('data-count', 'default') // 'hidden' to remove count
    div.setAttribute('data-theme', theme || 'light') // 'default' (light) or 'dark'

    subscribeRef.current?.appendChild(div)

    // // Remove old YouTube script before adding a new one
    // const existingScript = document.querySelector('script[src='https://apis.google.com/js/platform.js']')
    // if (existingScript) {
    //   existingScript.remove()
    // }

    // Reload YouTube script
    const script = document.createElement('script')
    script.src = 'https://apis.google.com/js/platform.js'
    script.async = true
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script) // Clean up old scripts when component unmounts or updates
    }
  }, [theme])

  return (!siteConfig.links.youtubeChannelId ? <></> :
    <div ref={subscribeRef} />
  )
}