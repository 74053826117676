import(/* webpackMode: "eager", webpackExports: ["DialogContainer"] */ "/vercel/path0/components/dialog.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/i18n/lang-context-client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LangMenuFlatHTML"] */ "/vercel/path0/components/i18n/lang-toggle.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Assistant"] */ "/vercel/path0/components/jbda/assistant.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CookieConsentLink"] */ "/vercel/path0/components/jbda/cookies-dialog.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/vercel/path0/components/jbda/header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LBAction","LBCallAction"] */ "/vercel/path0/components/jbda/lb/lb-action.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Section"] */ "/vercel/path0/components/jbda/section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LinkedInFollow","TwitterFollow","YouTubeSubscribe"] */ "/vercel/path0/components/jbda/social.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/vercel/path0/components/theme/theme-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ToastContainer"] */ "/vercel/path0/components/toast.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/ui/tooltip.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GitIndicator"] */ "/vercel/path0/components/utils/git-indicator.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ConsentContextProvider"] */ "/vercel/path0/contexts/ConsentContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["StoreProvider"] */ "/vercel/path0/contexts/reducers/StoreProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TooltipContextProvider"] */ "/vercel/path0/contexts/TooltipContext.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"lib/fonts.ts\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-sans\"}],\"variableName\":\"fontSans\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"lib/fonts.ts\",\"import\":\"JetBrains_Mono\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-mono\"}],\"variableName\":\"fontMono\"}");
