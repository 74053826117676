'use client'

import { useDialogActions } from '@/components/dialog'
import MD from '@/components/markdown'
import { Card } from '@/components/ui/card'
import { Flex, FlexCol } from '@/components/ui/flex'
import { useScreen } from '@/components/utils/windowSize'
import { getKoalendarUrl, siteConfig } from '@/config/site.config'
import { buildPhoneUrl, cn } from '@/lib/utils/utils'
import { FaPhone } from 'react-icons/fa'
import { FaCalendarDays } from 'react-icons/fa6'
import { I18nDictionary, useI18n } from '../../i18n/lang-context'
import { Button } from '../../ui/button'
import { useLBCalendarDialog } from './lb-calendar-dialog'

const I18N: I18nDictionary = {
  action: {
    en: 'Book a Call',
    fr: 'Réserver un appel'
  },
  becomePartner: {
    en: 'Become a Partner →',
    fr: 'Devenez partenaire →'
  },
  call: {
    en: 'Call ***Legibloq team*** directly at',
    fr: 'Appelez directement ***l\'équipe Legibloq*** au'
  },
  or: {
    en: 'or',
    fr: 'ou'
  },
  book1: {
    en: 'Choose a ***dedicated time slot***',
    fr: 'Préférez un ***créneau dédié*** pour'
  },
  book2: {
    en: 'to ***answer*** all your questions',
    fr: '***répondre*** à toutes vos questions et'
  },
  book3: {
    en: 'and ***kickstart*** your project now',
    fr: '***démarrer*** votre projet sans plus attendre'
  }
}

export function LBAction({ className, ifSmall = 'text', _I18N }: { className?: string, ifSmall?: 'hidden'|'icon'|'text', _I18N?: I18nDictionary }) {

  const screen = useScreen()
  const i18n = useI18n(_I18N || I18N)
  const { openLBCalendarDialog } = useLBCalendarDialog()
  const koalendarUrl = getKoalendarUrl()
  const isIcon = () => {
    return ifSmall === 'icon' && !screen.sm
  }
  const isHidden = () => {
    return !koalendarUrl || ifSmall === 'hidden' && !screen.sm
  }

  return (isHidden() ? <></> :
    <Button className={cn('p-8 text-lg', className, isIcon() ? ' pr-2 pl-2' : '')}
      onClick={() => openLBCalendarDialog(koalendarUrl as string)}
      id='Call Booking'>
      {isIcon() ? <FaCalendarDays/> : i18n.action}
    </Button>
  )
}

export function useLBCallDialog() {
  const { openDialog } = useDialogActions()

  const openLBCallDialog = () => {
    openDialog({ content: <LBCallDialogContent/> })
  }

  return { openLBCallDialog }
}

export function LBCallDialogContent() {
  const i18n = useI18n(I18N)
  return (
    <Card variant='consent' className='w-full p-1 sm:w-fit sm:min-w-[520px] sm:max-w-[520px] sm:p-4' style={{ margin: '20px' }}>
      <Flex className='justify-center gap-8 lg:gap-16'>
        <FlexCol className='flex-col items-center'>
          <MD className='font-medium text-center sm:text-lg pt-4 pb-2 opacity-80'>{i18n.call}</MD>
          <MD className='font-medium text-center sm:text-lg pb-4 opacity-80'>{'['+siteConfig.phone+']('+buildPhoneUrl(siteConfig.phone)+')'}</MD>
          <MD className='font-medium text-center pb-4 opacity-50'>{i18n.or}</MD>
          <MD className='font-medium text-center sm:text-lg pb-1 opacity-80'>{i18n.book1}</MD>
          <MD className='font-medium text-center sm:text-lg pb-1 opacity-80'>{i18n.book2}</MD>
          <MD className='font-medium text-center sm:text-lg pb-6 opacity-80'>{i18n.book3}</MD>
          <LBAction/>
        </FlexCol>
      </Flex>
    </Card>
  )
}

export function LBCallAction({ className }: { className?: string }) {
  const { openLBCallDialog } = useLBCallDialog()
  return (
    <Button className={cn('p-8 text-lg', className)} id='Call Legibloq'
      onClick={() => openLBCallDialog()}>
      <FaPhone/>
    </Button>
  )
}

export function LBBecomePartner({ className }: { className?: string }) {

  const i18n = useI18n(I18N)
  const { openLBCalendarDialog } = useLBCalendarDialog()
  const koalendarUrl = getKoalendarUrl()

  return (!koalendarUrl ? <></> :
    <div className={cn('cursor-pointer font-medium text-primary hover:underline dark:text-primaryBright', className)}
      onClick={() => openLBCalendarDialog(koalendarUrl)}>
      {i18n.becomePartner}
    </div>
  )
}
